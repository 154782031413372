export default {
  'listeners:create': () => import('./compose/createListener'),
  'listeners:delete': () => import('./compose/deleteListener'),
  'listeners:findById': () => import('./compose/findById'),
  'listeners:getAll': () => import('./compose/getAll'),
  'listeners:getPipelines': () => import('./compose/getPipelines'),
  'listeners:update': () => import('./compose/update'),
  'listeners:updateNameTags': () => import('./compose/updateNameTags'),
  'listeners:updateTags': () => import('./compose/updateTags'),
};
