import { Container, type ContainerType, type Module } from '@nstrlabs/sdk';
import {
  BaseError,
  type DomainError,
  domainErrorSchema,
} from '@nstrlabs/utils';
import actionsUseCases from './actions/UseCases';
import aiAssistantUseCases from './ai-assistant/UseCases';
import alertsUseCases from './alerts/UseCases';
import authUseCases from './auth/UseCases';
import chronographUseCases from './chronograph/UseCases';
import clustersUseCases from './clusters/UseCases';
import datasinksUseCases from './dataSinks/UseCases';
import listenersUseCases from './listeners/UseCases';
import lookupsUseCases from './lookups/UseCases';
import metricsUseCases from './metrics/UseCases';
import pipelinesUseCases from './pipelines/UseCases';
import secretsUseCases from './secrets/UseCases';
import settingsUseCases from './settings/UseCases';
import logger from './shared/infrastructure/logger';
import tenantsUseCases from './tenants/UseCases';
import usersUseCases from './users/UseCases';

const useCases = {
  ...actionsUseCases,
  ...aiAssistantUseCases,
  ...alertsUseCases,
  ...authUseCases,
  ...chronographUseCases,
  ...clustersUseCases,
  ...datasinksUseCases,
  ...listenersUseCases,
  ...lookupsUseCases,
  ...pipelinesUseCases,
  ...metricsUseCases,
  ...secretsUseCases,
  ...settingsUseCases,
  ...tenantsUseCases,
  ...usersUseCases,
  'labels:getPipelines': () =>
    import('./listeners/compose/getPipelinesByLabels'),
  'zones:get': () => import('./zones/compose/get'),
  'activity:getUserActivity': () => import('./activity/compose/getActivity'),
};

const isDomainError = (error: unknown): error is DomainError =>
  domainErrorSchema.safeParse(error).success;

export const container = Container.of(useCases);
container.middleWare(({ useCase, error }) => {
  if (isDomainError(error) && error.persistError) {
    logger.error('error', {
      module: 'context',
      function: useCase,
      errorCode: error.code,
      context: {
        trace: console.trace(),
        url: window.location.href.replace(window.location.origin, ''),
        error,
      },
    });
    return;
  }
  if (error instanceof BaseError) {
    logger.error('error', {
      module: error.name,
      function: useCase,
      errorCode: error.code,
      context: {
        trace: console.trace(),
        stack: error.stack,
        url: window.location.href.replace(window.location.origin, ''),
        error,
      },
    });
    return;
  }
  if ((error as { message: string }).message === 'Failed to fetch') {
    logger.error('error', {
      module: 'context',
      function: useCase,
      errorCode: 'WEB-66666',
      context: {
        trace: console.trace(),
        url: window.location.href.replace(window.location.origin, ''),
        error,
      },
    });
    return;
  }
  logger.error('error', {
    module: 'context',
    function: useCase,
    errorCode: 'WEB-00008',
    context: {
      trace: console.trace(),
      url: window.location.href.replace(window.location.origin, ''),
      error,
      name: (error as { name: string }).name,
      message: (error as { message: string }).message,
    },
  });
});

export type UseCases = typeof useCases;

export type UseCasesKey = keyof UseCases;

export type AppContainer = ContainerType<UseCases>;

type Command<UseCase extends Module> = Parameters<
  Awaited<ReturnType<UseCase>>['default']
>[0];

type Result<UseCase extends Module> = Awaited<
  ReturnType<Awaited<ReturnType<UseCase>>['default']>
>;

type ContainerUseCases = {
  [Key in keyof UseCases]: {
    command: Command<UseCases[Key]>;
    result: Result<UseCases[Key]>;
  };
};

export type ContainerUseCase<
  Key extends keyof ContainerUseCases,
  View extends 'command' | 'result',
> = ContainerUseCases[Key][View];
