import { Flex, type IconName } from '@nstrlabs/ixel';
import { invoke } from '@nstrlabs/utils';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import AddFilter from './AddFilter';
import FilterCategory from './FilterCategory';
import FilterDate from './FilterDate';
import FilterFloat from './FilterFloat';
import FilterInteger from './FilterInteger';
import FilterNumber from './FilterNumber';
import FilterSelector from './FilterSelector';
import FilterSelectorOption from './FilterSelectorOption';
import FilterString from './FilterString';
import FilterSuggested from './FilterSuggested';
import { type Filter, useNewFilters } from './FiltersProvider';

type FiltersProps = {
  addMoreFilters?: boolean;
  onChangeValue?: () => void;
};

const Filters = ({ addMoreFilters = true, onChangeValue }: FiltersProps) => {
  const { t } = useTranslation();
  const {
    filters,
    filtersToSelect,
    removeFilterDispatcher,
    saveFilterConfigDispatcher,
    addFilterDispatcher,
  } = useNewFilters();

  const handleSaveFilterConfigDispatcher = (filterToSave: Filter) => {
    saveFilterConfigDispatcher(filterToSave);
    invoke(onChangeValue);
  };

  return (
    <Flex gap="xs" flexWrap="wrap">
      {addMoreFilters ? <AddFilter icon /> : null}
      {filters.map((filter) =>
        match(filter)
          .with({ type: 'date' }, (filter) => (
            <FilterDate
              key={filter.field}
              filter={filter}
              handleSaveFilters={handleSaveFilterConfigDispatcher}
              onDelete={() => removeFilterDispatcher(filter)}
            />
          ))
          .with({ type: 'category' }, (filter) => (
            <FilterCategory
              key={filter.field}
              buttonName={t('BUTTONS.SAVE')}
              filter={filter}
              handleSaveFilters={handleSaveFilterConfigDispatcher}
              onDelete={() => removeFilterDispatcher(filter)}
            />
          ))
          .with({ type: 'selector' }, (filter) => (
            <FilterSelector
              key={filter.field}
              options={filter.options}
              iconName={filter.iconName}
              onClick={({ value }: { value: string }) => {
                handleSaveFilterConfigDispatcher({
                  ...filter,
                  value,
                });
              }}
              isFixed={filter.isFixed}
              renderOption={({ option, onClick }) => (
                <FilterSelectorOption
                  key={option.field}
                  element={option.label ?? ''}
                  onClick={onClick}
                  iconName={option.iconName as IconName}
                />
              )}
              onDelete={() => removeFilterDispatcher(filter)}
              isOpen={filter.isOpen}
              label={filter.label}
              filter={filter}
            />
          ))
          .with({ type: 'string' }, (filter) => (
            <FilterString
              key={filter.field}
              buttonName={t('BUTTONS.SAVE')}
              handleSaveFilters={handleSaveFilterConfigDispatcher}
              filter={filter}
              onDelete={() => removeFilterDispatcher(filter)}
            />
          ))
          .with({ type: 'number' }, (filter) => (
            <FilterNumber
              key={filter.field}
              buttonName={t('BUTTONS.SAVE')}
              handleSaveFilters={handleSaveFilterConfigDispatcher}
              filter={filter}
              onDelete={() => removeFilterDispatcher(filter)}
            />
          ))
          .with({ type: 'float' }, (filter) => (
            <FilterFloat
              key={filter.field}
              buttonName={t('BUTTONS.SAVE')}
              handleSaveFilters={handleSaveFilterConfigDispatcher}
              filter={filter}
              onDelete={() => removeFilterDispatcher(filter)}
            />
          ))
          .with({ type: 'integer' }, (filter) => (
            <FilterInteger
              key={filter.field}
              buttonName={t('BUTTONS.SAVE')}
              handleSaveFilters={handleSaveFilterConfigDispatcher}
              filter={filter}
              onDelete={() => removeFilterDispatcher(filter)}
            />
          ))
          .exhaustive(),
      )}

      {filtersToSelect.map((filter) =>
        filter.isSuggested ? (
          <FilterSuggested
            dataTestId={`filter-suggested-${filter.type}`}
            filter={filter}
            key={filter.field}
            onClick={() => addFilterDispatcher(filter as Filter)}
          />
        ) : null,
      )}
    </Flex>
  );
};

export default Filters;
