import { Dropdown, type DropdownProps } from '@nstrlabs/ixel';
import { invoke } from '@nstrlabs/utils';
import { type ReactElement, cloneElement, useState } from 'react';

export type DropdownInfoProps<T> = Omit<
  React.ComponentPropsWithoutRef<'div'>,
  'children'
> & {
  container?: HTMLElement | null;
  flipEnabled?: boolean;
  offset?: [number, number];
  defaultOpen?: boolean;
  placement?: DropdownProps<T>['placement'];
  size?: DropdownProps<T>['size'];
  trigger: ReactElement;
  children: ReactElement;
  onClick?: (event: React.MouseEvent<T>) => void;
  onClose?: () => void;
  onOpen?: () => void;
  onTargetClick?: (target: EventTarget) => boolean;
};

const DropdownInfo = <T extends HTMLElement>({
  children,
  trigger,
  defaultOpen = false,
  onClose,
  onClick,
  onOpen,
  onKeyDown,
  ...props
}: DropdownInfoProps<T>) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleClose = () => {
    setOpen(false);
    invoke(onClose);
  };
  const handleClick = (event: React.MouseEvent<T>) => {
    setOpen(true);
    invoke(onClick, event);
  };

  return (
    <Dropdown<T>
      {...props}
      open={open}
      onClose={handleClose}
      onOpen={onOpen}
      renderTrigger={(ref) =>
        cloneElement(trigger, {
          ref,
          onClick: handleClick,
          onKeyDown,
        })
      }
    >
      {children}
    </Dropdown>
  );
};

export default DropdownInfo;
