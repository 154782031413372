export default {
  'pipelines:allDatasinks': () => import('./compose/allDatasinks'),
  'pipelines:createInternalPipeline': () =>
    import('./compose/createInternalPipeline'),
  'pipelines:createPipeline': () => import('./compose/createPipeline'),
  'pipelines:debug:publish': () => import('./compose/debug/publish'),
  'pipelines:debug:pull': () => import('./compose/debug/pull'),
  'pipelines:debug:unpublish': () => import('./compose/debug/unpublish'),
  'pipelines:debug:update': () => import('./compose/debug/update'),
  'pipelines:delete': () => import('./compose/deletePipeline'),
  'pipelines:deleteByIds': () => import('./compose/deletePipelines'),
  'pipelines:deleteInternalPipeline': () =>
    import('./compose/deleteInternalPipeline'),
  'pipelines:duplicatePipeline': () => import('./compose/duplicatePipeline'),
  'pipelines:getAll': () => import('./compose/getAll'),
  'pipelines:getById': () => import('./compose/getById'),
  'pipelines:getByIdAndVersion': () => import('./compose/getByIdAndVersion'),
  'pipelines:getInternalPipelineLogsSamples': () =>
    import('./compose/getInternalPipelineLogsSamples'),
  'pipelines:getLogsSamples': () => import('./compose/getLogsSamples'),
  'pipelines:listeners': () => import('./compose/getAllListeners'),
  'pipelines:modifyPipelineStatus': () =>
    import('./compose/modifyPipelineStatus'),
  'pipelines:startPipelines': () => import('./compose/startPipelines'),
  'pipelines:stopPipelines': () => import('./compose/stopPipelines'),
  'pipelines:update': () => import('./compose/update'),
  'pipelines:updateNameAndTags': () => import('./compose/updateNameAndTags'),
  'pipelines:updateVersionNameAndDescription': () =>
    import('./compose/updateVersionNameAndDescription'),
  'pipelines:getByActionName': () =>
    import('./compose/getPipelinesByActionName'),
};
