import type { LogLevels, Logger } from '../domain/Logger';
import { ConsoleLogger } from './ConsoleLogger';
// TODO: Try to move all the logging to a service worker.
import { DevoWithSentryLogger } from './DevoWithSentryLogger';

type LoggerFactory = Record<string, () => Logger>;

const logLevel = process.env.VITE_LOGGER_LEVEL as LogLevels;
const method = process.env.VITE_LOGGER_METHOD ?? '';

const loggerFactory: LoggerFactory = {
  cons: () => new ConsoleLogger(logLevel),
  devo: () => new DevoWithSentryLogger(logLevel),
};

const logMethod = method in loggerFactory ? method : 'cons';

const logger: Logger = loggerFactory[logMethod]();

export default logger;
