import type { ReactNode } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

export const NestedDetailRoute = ({ children }: { children: ReactNode }) => {
  const { mainContentWidth, handleTransition } = useOutletContext<{
    mainContentWidth: number;
    handleTransition: (state: boolean) => void;
  }>();
  return (
    <>
      {children}
      <Outlet context={{ mainContentWidth, handleTransition }} />
    </>
  );
};
