import { Typography } from '@nstrlabs/ixel';
import { normalizeIncludes, rangeLast7Days } from '@nstrlabs/utils';
import UserItem from '../../../components/molecules/UserItem';
import type {
  Filter,
  FilterToSelect,
  RangeDate,
} from '../../../components/organisms/NewFilters/FiltersProvider';
import type { ContainerUseCase } from '../../../context';

type DefaultDate = {
  from: string;
  to: string;
  preset: RangeDate;
};

const filtersTranslationRoot = 'SETTINGS.TENANT.ACTIVITY.FILTERS';

const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const lastSevenDays = rangeLast7Days({ timeZone: TIME_ZONE });
const defaultFromTo = () =>
  lastSevenDays.from !== null && lastSevenDays.to !== null
    ? {
        from: lastSevenDays.from.toISOString(),
        to: lastSevenDays.to.toISOString(),
      }
    : { from: '', to: '' };

const DEFAULT_DATE: DefaultDate = {
  from: defaultFromTo().from,
  to: defaultFromTo().to,
  preset: 'LAST_7_DAYS',
};

const backgroundColorType = [
  'darkViolet',
  'violet',
  'blue',
  'green',
  'gray',
  'darkGreen',
  'orange',
  'brown',
  'red',
  'pink',
] as const;

type CategoryFilterOptions = Record<'id' | 'label', string>;
type Users = ContainerUseCase<'settings:getUsers', 'result'>;

const randomNumberWithLength = (length: number) =>
  Math.floor(Math.random() * length);

type UserCategoryLabel = {
  firstName: string;
  lastName: string;
  email: string;
  color: string;
};

export type UserCategory = {
  id: string;
  label: UserCategoryLabel;
};

type FilterItemUserTextProps = { usersSelected: UserCategory[] };

const FilterItemUserText = ({ usersSelected }: FilterItemUserTextProps) => {
  const composeFilterText = (usersSelectedArg: UserCategory[]) => {
    if (usersSelectedArg) {
      const labels = usersSelectedArg?.map(({ label }) => label.firstName);
      if (labels.length <= 3) {
        return labels.join(', ');
      }
      return `${labels[0]} and ${labels.length - 1} more`;
    }
    return '';
  };
  return <Typography>{composeFilterText(usersSelected)}</Typography>;
};

const composeUserFilter = (users: Users) => {
  return users.map((user) => {
    const userMap = {
      id: user?.id ?? '',
      label: {
        firstName: user?.name ?? '',
        lastName: user?.surname ?? '',
        email: user?.email ?? '',
        color:
          backgroundColorType[
            randomNumberWithLength(backgroundColorType.length)
          ],
      },
    };
    return userMap;
  });
};

const searchUser = (data: UserCategory[], search: string) => {
  return data?.filter(
    (field) =>
      normalizeIncludes(field.label.firstName, search) ||
      normalizeIncludes(field.label.lastName, search) ||
      normalizeIncludes(field.label.email, search),
  );
};

export const filters: Filter[] = [
  {
    label: `${filtersTranslationRoot}.RANGE_DATE`,
    field: 'rangeData',
    type: 'date',
    iconName: 'calendar',
    value: DEFAULT_DATE,
    isOpen: false,
    isFixed: true,
    id: 'rangeData',
    defaultPreset: DEFAULT_DATE.preset,
  },
];

export const operationOptions: CategoryFilterOptions[] = [
  {
    id: 'create',
    label: `${filtersTranslationRoot}.OPERATION.CREATE`,
  },
  {
    id: 'update',
    label: `${filtersTranslationRoot}.OPERATION.UPDATE`,
  },
  {
    id: 'delete',
    label: `${filtersTranslationRoot}.OPERATION.DELETE`,
  },
  {
    id: 'deprecate',
    label: `${filtersTranslationRoot}.OPERATION.DEPRECATE`,
  },
  {
    id: 'start',
    label: `${filtersTranslationRoot}.OPERATION.START`,
  },
  {
    id: 'stop',
    label: `${filtersTranslationRoot}.OPERATION.STOP`,
  },
];

export const entityOptions: CategoryFilterOptions[] = [
  {
    id: 'certificate',
    label: `${filtersTranslationRoot}.ENTITY.CERTIFICATE`,
  },
  {
    id: 'cluster',
    label: `${filtersTranslationRoot}.ENTITY.CLUSTER`,
  },
  {
    id: 'datasink',
    label: `${filtersTranslationRoot}.ENTITY.DATASINK`,
  },
  {
    id: 'debugPipeline',
    label: `${filtersTranslationRoot}.ENTITY.DEBUG_PIPELINE`,
  },
  {
    id: 'label',
    label: `${filtersTranslationRoot}.ENTITY.LABEL`,
  },
  {
    id: 'listener',
    label: `${filtersTranslationRoot}.ENTITY.LISTENER`,
  },
  {
    id: 'lookup',
    label: `${filtersTranslationRoot}.ENTITY.LOOKUP`,
  },
  {
    id: 'lookupVersion',
    label: `${filtersTranslationRoot}.ENTITY.LOOKUP_VERSION`,
  },
  {
    id: 'pipeline',
    label: `${filtersTranslationRoot}.ENTITY.PIPELINE`,
  },
  {
    id: 'secret',
    label: `${filtersTranslationRoot}.ENTITY.SECRET`,
  },
  {
    id: 'tenant',
    label: `${filtersTranslationRoot}.ENTITY.TENANT`,
  },
  { id: 'user', label: `${filtersTranslationRoot}.ENTITY.USER` },
  { id: 'zone', label: `${filtersTranslationRoot}.ENTITY.ZONE` },
];

const filtersToSelect: FilterToSelect[] = [
  {
    label: 'SETTINGS.TENANT.ACTIVITY.FIELDS.OPERATION',
    field: 'operation',
    type: 'category',
    iconName: 'action',
    isOpen: true,
    isFixed: false,
    data: operationOptions,
  },
  {
    label: 'SETTINGS.TENANT.ACTIVITY.FIELDS.ENTITY',
    field: 'entity',
    type: 'category',
    iconName: 'sankey',
    isOpen: true,
    isFixed: false,
    data: entityOptions,
  },
  // TODO: Add the rest of the filters with regex.
  // {
  //   label: 'SETTINGS.TENANT.ACTIVITY.FIELDS.NAME',
  //   field: 'name',
  //   type: 'string',
  //   iconName: 'name',
  //   isOpen: true,
  //   isFixed: false,
  // },
  // {
  //   label: 'SETTINGS.TENANT.ACTIVITY.FIELDS.IP_ADDRESS',
  //   field: 'ipaddress',
  //   type: 'string',
  //   iconName: 'ip',
  //   isOpen: true,
  //   isFixed: false,
  // },
  {
    label: 'SETTINGS.TENANT.ACTIVITY.FIELDS.USER',
    field: 'user',
    type: 'category',
    iconName: 'user',
    isFixed: false,
    isSuggested: true,
    data: [],
    renderFilterItem: (usersSelected: UserCategory[]) => (
      <FilterItemUserText usersSelected={usersSelected} />
    ),
    renderCheckboxLabel: (userInfo: Record<string, string>) => (
      <UserItem userInfo={userInfo} />
    ),
    customSearchFunction: (data: UserCategory[], search: string) =>
      searchUser(data, search),
  },
];

export const composeFilterToSelect = (
  users: ContainerUseCase<'settings:getUsers', 'result'>,
) => {
  const filtersToSelectHydrated = filtersToSelect.map((filter) => {
    if (filter.type === 'category' && filter.field === 'user') {
      return { ...filter, data: composeUserFilter(users) };
    }
    return filter;
  });
  return { filtersToSelect: filtersToSelectHydrated ?? [] };
};
