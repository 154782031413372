import { type InvalidUuid, uuidValue } from '@nstrlabs/sdk';
import { Either, type Value, domainError } from '@nstrlabs/utils';

export type ActionId = Value<string, 'action_id'>;

export const CONTEXT_PREFIX = 'AM';

export const invalidActionIdPrefix = domainError<{ value: string }>()(
  'action:id',
  'WEB-00103',
  'The id is invalid',
);

export type InvalidActionIdPrefix =
  | ReturnType<typeof invalidActionIdPrefix>
  | InvalidUuid;

export const makeActionId = (
  actionId: string,
): Either<InvalidActionIdPrefix, ActionId> => {
  const [context, uuid] = actionId.split('_');
  return uuidValue(uuid).flatMap(() => {
    if (context !== CONTEXT_PREFIX) {
      return Either.left(invalidActionIdPrefix({ value: actionId }));
    }

    return Either.of(actionId as ActionId);
  });
};
