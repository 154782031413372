import { Either, type Value, domainError } from '@nstrlabs/utils';

export type ActionVersion = Value<string, 'action_version'>;

export const VERSION_REGEX = /\d{1,3}\.\d{1,3}\.\d{1,3}/;

const invalidActionVersion = domainError<{
  value: string;
}>()('action:version', 'WEB-00105', 'Version format is incorrect');

export type InvalidActionVersion = ReturnType<typeof invalidActionVersion>;

export const makeVersion = (
  value: string,
): Either<InvalidActionVersion, ActionVersion> => {
  if (!VERSION_REGEX.test(value)) {
    return Either.left(
      invalidActionVersion({
        value,
      }),
    );
  }

  return Either.of(value as ActionVersion);
};
