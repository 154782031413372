import { Avatar, Flex, Typography } from '@nstrlabs/ixel';
import styles from './index.module.css';

const backgroundColorType = [
  'darkViolet',
  'violet',
  'blue',
  'green',
  'gray',
  'darkGreen',
  'orange',
  'brown',
  'red',
  'pink',
] as const;
export type BackgroundColorType = (typeof backgroundColorType)[number];
export type UserItemProps = {
  userInfo: Record<string, string>;
  avatarBackground?: BackgroundColorType;
};

const UserItem = ({ userInfo, avatarBackground = 'violet' }: UserItemProps) => {
  const { firstName, lastName, email, color } = userInfo;
  return (
    <Flex flexDirection="row" gap="xs" alignItems="center">
      <Avatar
        className={styles.noHover}
        background={(color as BackgroundColorType) ?? avatarBackground}
        user={{
          firstName: firstName,
          lastName: lastName,
        }}
      />
      <Flex flexDirection="column" className={styles.currentInnerContainer}>
        <Flex gap="xxs">
          <Typography bold>{firstName}</Typography>
          <Typography bold>{lastName}</Typography>
        </Flex>
        <Typography color="disabled">{email}</Typography>
      </Flex>
    </Flex>
  );
};

export default UserItem;
