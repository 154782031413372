import { Dropdown, type DropdownProps } from '@nstrlabs/ixel';
import { invoke } from '@nstrlabs/utils';
import { type ReactElement, cloneElement, useState } from 'react';
import useDoubleClick from '../../../hooks/useDoubleClick';

type OpenState = {
  open: boolean;
  closeDropdown: () => void;
};

export type DropdownMenuProps<T> = Omit<
  React.ComponentPropsWithoutRef<'div'>,
  'children'
> & {
  children: ({ open, closeDropdown }: OpenState) => ReactElement | null;
  clickEvent?: DropdownProps<T>['clickEvent'];
  container?: HTMLElement | undefined | null;
  defaultOpen?: boolean;
  flipEnabled?: boolean;
  inline?: boolean;
  offset?: DropdownProps<T>['offset'];
  placement?: DropdownProps<T>['placement'];
  size?: DropdownProps<T>['size'];
  trigger: ReactElement;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<T>) => void;
  onDoubleClick?: () => void;
  onClose?: () => void;
  onOpen?: (dropdownEl: HTMLElement) => void;
  /**
   * @deprecated This property should not be used.
   * Deprecated in favor of `onClick` over Dropdown or
   * assigning the `onClick` directly to the trigger element
   */
  onTargetClick?: (target?: EventTarget | null) => boolean;
};

const DropdownMenu = <T extends HTMLElement>({
  children,
  trigger,
  defaultOpen = false,
  disabled = false,
  onDoubleClick,
  onClose,
  onClick,
  onOpen,
  onKeyDown,
  /**
   * @deprecated This property should not be used.
   * Deprecated in favor of `onClick` over Dropdown or
   * assigning the `onClick` directly to the trigger element
   */
  onTargetClick: _onTargetClick,
  ...props
}: DropdownMenuProps<T>) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleOnClose = () => {
    setOpen(false);
    invoke(onClose);
  };

  const handleOnOpen = (el: HTMLElement) => {
    if (!onDoubleClick) setOpen(true);
    invoke(onOpen, el);
  };

  const handleOnClick = (event: React.MouseEvent<T>) => {
    if (!disabled) setOpen(!open);
    invoke(onClick, event);
  };

  const handleDoubleClick = useDoubleClick({
    onClick: (e) => handleOnClick(e as unknown as React.MouseEvent<T>),
    onDoubleClick: () => {
      invoke(onDoubleClick);
      setOpen(false);
    },
    delay: 200,
  });

  const dataCy =
    (trigger?.props as Record<string, string>)['data-cy'] ??
    'dropdown-menu-trigger';
  return (
    <Dropdown<T>
      {...props}
      open={open}
      onClose={handleOnClose}
      onOpen={handleOnOpen}
      onClick={onDoubleClick ? handleDoubleClick : handleOnClick}
      disabled={disabled}
      renderTrigger={(ref) =>
        cloneElement(trigger, {
          ref,
          onKeyDown,
          'data-cy': dataCy,
        })
      }
    >
      {children({ open, closeDropdown: handleOnClose })}
    </Dropdown>
  );
};

export default DropdownMenu;
