import type { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { Logger } from '../../context/shared/domain/Logger';
import GenericError from '../../pages/GenericError';

type ErrorFallbackProps = {
  error: ErrorShape;
  logger: Logger;
  resetErrorBoundary: () => void;
};

type ErrorShape = {
  name: string;
  message: string;
};

// https://dev.to/edemagbenyo/handle-errors-in-react-components-like-a-pro-l7l
const ErrorFallback = ({
  error,
  logger,
  resetErrorBoundary,
}: ErrorFallbackProps) => {
  const { name, message } = error;

  logger.error('error', {
    module: 'CustomErrorBoundary',
    function: 'ErrorFallback',
    errorCode: 'WEB-00000',
    context: {
      kind: 'Uncontrolled error',
      code: 'WEB-00000',
      name,
      message,
      originalError: error,
    },
  });
  return <GenericError resetErrorBoundary={resetErrorBoundary} />;
};

type CustomErrorBoundaryProps = {
  logger: Logger;
};

const CustomErrorBoundary = ({
  children,
  logger,
}: PropsWithChildren<CustomErrorBoundaryProps>) => (
  <ErrorBoundary
    FallbackComponent={({ error, resetErrorBoundary }) =>
      ErrorFallback({ error, logger, resetErrorBoundary })
    }
    onReset={() => {
      window.location.href = '/';
    }}
  >
    {children}
  </ErrorBoundary>
);

export default CustomErrorBoundary;
