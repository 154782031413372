export default {
  'featureFlags:get': () => import('./compose/getFeatureFlags'),
  'settings:getUserSettings': () => import('./compose/getUserSettings'),
  'settings:saveUserSettings': () => import('./compose/saveUserSettings'),
  'organizationSettings:secret:getSecrets': () =>
    import('./compose/getSecrets'), // TODO maybe this in secrets folder?
  'organizationSettings:secret:createSecret': () =>
    import('./compose/createSecret'), // TODO maybe this in secrets folder?
  'secrets:getUsages': () => import('./compose/getSecretUsages'), // TODO maybe this in secrets folder?
  'settings:deleteUsers': () => import('./compose/deleteUsers'), // TODO maybe this in users folder?
  'settings:getRoles': () => import('./compose/getRoles'), // TODO maybe this in users folder?
  'settings:getUsers': () => import('./compose/getUsers'), // TODO maybe this in users folder?
  'settings:inviteUsers': () => import('./compose/inviteUsers'), // TODO maybe this in users folder?
  'settings:updateRoles': () => import('./compose/updateRoles'), // TODO maybe this in users folder?
};
