/* eslint-disable func-names */
import { DateRangePicker, Flex, Icon, Typography } from '@nstrlabs/ixel';
import {
  formatDateDayMonthYear,
  rangeLast7Days,
  rangeLast24Hours,
  rangeLast28Days,
  rangeLastMonth,
  rangeLastWeek,
  rangeOneHourAgo,
  rangeYesterday,
} from '@nstrlabs/utils';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import type { FilterDateValuePrimitives } from '../../../../context/shared/domain/Criteria/FilterDateValue/FilterDateValue';
import FilterItem from '../FilterItem';
import type { FilterWithValue } from '../FiltersProvider';
import stylesCss from './index.module.css';

export type FilterDatesProps = React.HTMLAttributes<HTMLDivElement> & {
  filter: FilterWithValue<'date', FilterDateValuePrimitives>;
  onDelete: () => void;
  handleSaveFilters: (
    filterToSave: FilterWithValue<'date', FilterDateValuePrimitives>,
  ) => void;
};

export const timesStampDifferencePresetObject = ({
  timeZone,
}: {
  timeZone: string;
}) => {
  type DateState = {
    from: Date | null;
    to: Date | null;
    fromYear: number;
    toYear: number;
    fromMonth: number;
    toMonth: number;
  };

  const allPresetsFromAndTo: Record<string, DateState> = {
    ONE_HOUR_AGO: rangeOneHourAgo({ timeZone }),
    LAST_24_HOURS: rangeLast24Hours({ timeZone }),
    YESTERDAY: rangeYesterday({ timeZone }),
    LAST_7_DAYS: rangeLast7Days({ timeZone }),
    LAST_WEEK: rangeLastWeek({ timeZone }),
    LAST_28_DAYS: rangeLast28Days({ timeZone }),
    LAST_MONTH: rangeLastMonth({ timeZone }),
  };

  const timeDifferences = Object.keys(allPresetsFromAndTo).reduce(
    (result: Record<number, string>, key) => {
      const preset = allPresetsFromAndTo[key];
      const difference =
        preset.from && preset.to
          ? Math.abs(preset.from.getTime() - preset.to.getTime())
          : 0;
      return { ...result, [difference]: key };
    },
    {},
  );

  return timeDifferences;
};

const FilterDate = ({
  filter,
  onDelete,
  handleSaveFilters,
}: FilterDatesProps) => {
  const {
    value,
    field,
    isOpen,
    isFixed,
    defaultPreset = 'ONE_HOUR_AGO',
  } = filter;
  const { t } = useTranslation();
  const localeText = {
    ONE_HOUR_AGO: t('CALENDAR.ONE_HOUR'),
    LAST_24_HOURS: t('CALENDAR.24_HOUR'),
    YESTERDAY: t('CALENDAR.YESTERDAY'),
    LAST_7_DAYS: t('CALENDAR.7_DAYS'),
    LAST_WEEK: t('CALENDAR.LAST_WEEK'),
    LAST_28_DAYS: t('CALENDAR.28_DAYS'),
    LAST_MONTH: t('CALENDAR.LAST_MONTH'),
    CUSTOM_RANGE: t('CALENDAR.CUSTOM_RANGE'),
    APPLY: t('MODAL.ACTIONS.SAVE'),
  };
  // On the future this will came from settings as timezone will be set on settings
  // the one the user choose or default os one
  const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { from, to, preset } = value as Record<string, string>;

  const resultTrial = timesStampDifferencePresetObject({
    timeZone: TIME_ZONE,
  });
  // Is necessary the 999 ms to cover the last full day.
  const fromToDifferenceTimeStamp = match(preset)
    .with(
      'YESTERDAY',
      'LAST_WEEK',
      'LAST_MONTH',
      () => Math.abs(new Date(from).getTime() - new Date(to).getTime()) + 999,
    )
    .otherwise(() =>
      Math.abs(new Date(from).getTime() - new Date(to).getTime()),
    );

  const activePresetText =
    preset !== 'CUSTOM_RANGE'
      ? (resultTrial[fromToDifferenceTimeStamp] ?? 'CUSTOM_RANGE')
      : 'CUSTOM_RANGE';

  const onChangeDate = (dateApplied: Record<string, string>) => {
    handleSaveFilters({
      ...filter,
      value: dateApplied as FilterDateValuePrimitives,
    });
  };
  const onReset = () => {
    // On the future this will came from settings as timezone will be set on settings
    // the one the user choose or default os one

    const defaultRangeData = match(defaultPreset)
      .with('ONE_HOUR_AGO', () => rangeOneHourAgo({ timeZone: TIME_ZONE }))
      .with('LAST_24_HOURS', () => rangeLast24Hours({ timeZone: TIME_ZONE }))
      .with('YESTERDAY', () => rangeYesterday({ timeZone: TIME_ZONE }))
      .with('LAST_7_DAYS', () => rangeLast7Days({ timeZone: TIME_ZONE }))
      .with('LAST_WEEK', () => rangeLastWeek({ timeZone: TIME_ZONE }))
      .with('LAST_28_DAYS', () => rangeLast28Days({ timeZone: TIME_ZONE }))
      .with('LAST_MONTH', () => rangeLastMonth({ timeZone: TIME_ZONE }))
      .otherwise(() => rangeOneHourAgo({ timeZone: TIME_ZONE }));

    const defaultFromTo = () =>
      defaultRangeData.from !== null && defaultRangeData.to !== null
        ? {
            from: defaultRangeData.from.toISOString(),
            to: defaultRangeData.to.toISOString(),
          }
        : { from: '', to: '' };
    handleSaveFilters({
      ...filter,
      value: {
        from: defaultFromTo().from,
        to: defaultFromTo().to,
        preset: defaultPreset,
      } as FilterDateValuePrimitives,
    });
  };

  const handleClose = () => {
    if (!from && !to) {
      onDelete();
    }
  };

  const textInFilterItem = () => {
    if (from && to) {
      return activePresetText === 'CUSTOM_RANGE'
        ? formatDateDayMonthYear(new Date(from), new Date(to))
        : localeText[
            `${resultTrial[fromToDifferenceTimeStamp] as keyof typeof localeText}`
          ];
    }
    return null;
  };

  return (
    <DateRangePicker
      placement="bottom-start"
      isOpen={isOpen ?? false}
      onSave={onChangeDate}
      localeText={localeText}
      from={from ? new Date(from) : undefined}
      to={to ? new Date(to) : undefined}
      activePreset={activePresetText}
      onClose={handleClose}
      triggerElement={
        <FilterItem
          onReset={
            field === 'rangeData' && preset !== defaultPreset
              ? onReset
              : undefined
          }
          onDelete={onDelete}
          isFixed={isFixed}
          name={
            <Flex>
              <Icon name="calendar" />
              <Typography className={stylesCss.text}>
                {t('FILTERS.CALENDAR.ITEM')}
              </Typography>
              <Typography bold>{textInFilterItem()}</Typography>
            </Flex>
          }
        />
      }
    />
  );
};

export default FilterDate;
