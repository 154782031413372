export default {
  'datasinks:create': () => import('./compose/createDatasink'),
  'datasinks:delete': () => import('./compose/deleteDatasink'),
  'datasinks:findById': () => import('./compose/findById'),
  'datasinks:getAll': () => import('./compose/getAll'),
  'datasinks:getPipelines': () => import('./compose/getPipelines'),
  'datasinks:update': () => import('./compose/update'),
  'datasinks:updateNameTags': () => import('./compose/updateNameTags'),
  'datasinks:updateTags': () => import('./compose/updateTags'),
};
