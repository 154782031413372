export default {
  'lookups:createLookup': () => import('./compose/createLookup'),
  'lookups:deleteLookup': () => import('./compose/deleteLookup'),
  'lookups:deleteLookupVersion': () => import('./compose/deleteLookupVersion'),
  'lookups:findLookupVersion': () => import('./compose/findLookupVersion'),
  'lookups:getLookupById': () => import('./compose/getLookupById'),
  'lookups:getLookupsSummary': () => import('./compose/getLookupsSummary'),
  'lookups:getPipelines': () => import('./compose/getPipelines'),
  'lookups:updateLookup': () => import('./compose/updateLookup'),
  'lookups:uploadNewLookupVersion': () =>
    import('./compose/uploadNewLookupVersion'),
};
