export default {
  'actions:getByIdAndVersion': () => import('./compose/getAction'),
  'actions:getActionsSummary': () => import('./compose/getActionsSummary'),
  'actions:getMetadata': () => import('./compose/getMetadata'),
  'actions:getOutdatedActions': () => import('./compose/getOutdatedActions'),
  'actions:parser:addDelimiter': () =>
    import('./compose/ParserAction/addDelimiter'),
  'actions:parser:reParse': () => import('./compose/ParserAction/reParse'),
  'actions:parser:addToken': () => import('./compose/ParserAction/addToken'),
  'actions:parser:discoverLog': () =>
    import('./compose/ParserAction/discoverLog'),
  'actions:parser:parseLog': () => import('./compose/ParserAction/parseLog'),
  'actions:parser:removeDelimiter': () =>
    import('./compose/ParserAction/removeDelimiter'),
  'actions:parser:updateFields': () =>
    import('./compose/ParserAction/updateFields'),
  'actions:parser:updateNames': () =>
    import('./compose/ParserAction/updateNames'),
  'actions:parser:updateTypes': () =>
    import('./compose/ParserAction/updateTypes'),
  'actions:testing:test': () => import('./compose/ActionTesting/testAction'),
};
