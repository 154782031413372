export default {
  'auth:changeTenant': () => import('./compose/changeTenant'),
  'auth:forgetPassword': () => import('./compose/forgetPassword'),
  'auth:getAuthToken': () => import('./compose/getAuthToken'),
  'auth:login': () => import('./compose/login'),
  'auth:loginRedirectResult': () => import('./compose/loginRedirectResult'),
  'auth:loginWithIdentityProvider': () =>
    import('./compose/loginWithIdentityProvider'),
  'auth:logout': () => import('./compose/logout'),
  'auth:logoutOnIdle': () => import('./compose/logoutOnIdle'),
  'auth:onIdentityProviderTokenChange': () =>
    import('./compose/onIdentityProviderTokenChange'),
  'auth:resendInviteUsers': () => import('./compose/resendInviteUsers'),
  'auth:resetPassword': () => import('./compose/resetPassword'),
  'auth:updatePassword': () => import('./compose/updatePassword'),
  'auth:verifyNewUser': () => import('./compose/verifyNewUser'),
  'auth:verifyUser': () => import('./compose/verifyUser'),
};
