import { Suspense, lazy } from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import FeatureFlags from '../../components/atoms/FeatureFlag';
import AuthLayout from '../../components/layouts/AuthLayout';
import MainLayout from '../../components/layouts/MainLayout';
import WithPermissions from '../../hooks/controlAccess/WithPermissions';
import NotFound from '../../pages/NotFound';
import { actionsRoutes } from './ActionsRoutes';
import {
  dataSinkCreatePipelineRoutes,
  dataSinkEditRoute,
  dataSinkViewRoute,
  dataSinksNestedRoutes,
} from './DatasinksNestedRoutes';
import { enrichmentsNestedRoutes } from './EnrichmentsNestedRoutes';
import ErrorView from './ErrorView';
import {
  listenerCreatePipelineRoutes,
  listenerEditRoute,
  listenerViewRoute,
  listenersNestedRoutes,
} from './ListenersNestedRoutes';
import { NestedDetailRoute } from './NestedDetailsRoute';
import {
  pipelineDetailsRoute,
  pipelineFocusRoute,
} from './PipelinesNestedRoutes';

const Alerts = lazy(() => import('../../pages/Alerts'));
const DataHistory = lazy(() => import('../../pages/DataHistory'));
const DataSinks = lazy(() => import('../../pages/DataSinks'));
const Enrichment = lazy(() => import('../../pages/Enrichments/Enrichment'));
const ForgotPassword = lazy(() => import('../../pages/ForgotPassword'));
const Home = lazy(() => import('../../pages/Home'));
const Join = lazy(() => import('../../pages/Join'));
const Listeners = lazy(() => import('../../pages/Listeners'));
const Login = lazy(() => import('../../pages/Login'));
const Version = lazy(() => import('../../pages/Version'));
const Pipelines = lazy(() => import('../../pages/Pipelines'));
const ResetPassword = lazy(() => import('../../pages/ResetPassword'));
const Welcome = lazy(() => import('../../pages/Welcome'));

export const routes = createRoutesFromElements(
  <>
    <Route
      path="/web-app-front"
      element={
        <Suspense>
          <Login />
        </Suspense>
      }
      errorElement={<ErrorView />}
    />
    <Route
      path="/"
      element={
        <Suspense>
          <Login />
        </Suspense>
      }
      errorElement={<ErrorView />}
    />
    <Route
      path="version"
      element={
        <Suspense>
          <Version />
        </Suspense>
      }
      errorElement={<ErrorView />}
    />
    <Route
      path="login"
      element={
        <Suspense>
          <Login />
        </Suspense>
      }
      errorElement={<ErrorView />}
    />
    <Route
      path="forgot"
      element={
        <Suspense>
          <ForgotPassword />
        </Suspense>
      }
    />

    <Route
      path="reset"
      element={
        <Suspense>
          <ResetPassword />
        </Suspense>
      }
    />
    <Route
      path="welcome"
      element={
        <Suspense>
          <Welcome />
        </Suspense>
      }
      errorElement={<ErrorView />}
    />
    <Route element={<AuthLayout />} errorElement={<ErrorView />}>
      <Route
        path="join"
        element={
          <Suspense>
            <Join />
          </Suspense>
        }
      />
      <Route
        element={
          <Suspense>
            <MainLayout />
          </Suspense>
        }
      >
        <Route
          path="alerts"
          element={
            <FeatureFlags flag="alerts" fallback={<NotFound />}>
              <NestedDetailRoute>
                <Suspense>
                  <Alerts />
                </Suspense>
              </NestedDetailRoute>
            </FeatureFlags>
          }
        />
        <Route
          path="home"
          element={
            <NestedDetailRoute>
              <Suspense>
                <Home />
              </Suspense>
            </NestedDetailRoute>
          }
        >
          {listenerCreatePipelineRoutes}
          {dataSinkCreatePipelineRoutes}
          {listenerViewRoute}
          {listenerEditRoute}
          {dataSinkViewRoute}
          {dataSinkEditRoute}
          {pipelineFocusRoute}
        </Route>
        <Route
          path="datasinks"
          element={
            <WithPermissions resource="datasink">
              <NestedDetailRoute>
                <Suspense>
                  <DataSinks />
                </Suspense>
              </NestedDetailRoute>
            </WithPermissions>
          }
        >
          {dataSinksNestedRoutes}
        </Route>
        <Route
          path="listeners"
          element={
            <WithPermissions resource="listener">
              <NestedDetailRoute>
                <Suspense>
                  <Listeners />
                </Suspense>
              </NestedDetailRoute>
            </WithPermissions>
          }
        >
          {listenersNestedRoutes}
        </Route>
        <Route
          path="pipelines"
          element={
            <WithPermissions resource="pipeline">
              <NestedDetailRoute>
                <Suspense>
                  <Pipelines />
                </Suspense>
              </NestedDetailRoute>
            </WithPermissions>
          }
        >
          {pipelineFocusRoute}
        </Route>
        <Route
          path="datahistory"
          element={
            <Suspense>
              <DataHistory />
            </Suspense>
          }
        />
        <Route
          path="enrichments"
          element={
            <WithPermissions resource="listener">
              <NestedDetailRoute>
                <Suspense>
                  <Enrichment />
                </Suspense>
              </NestedDetailRoute>
            </WithPermissions>
          }
        >
          {enrichmentsNestedRoutes}
        </Route>
        {actionsRoutes}
        {/* Insert new route in homes on the line above */}
      </Route>
      {pipelineDetailsRoute}
    </Route>
    <Route path="*" element={<NotFound />} />
  </>,
);

export const router = createBrowserRouter(routes);
