import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useContainer } from '../useContainer';

export function useOutdatedActions() {
  const container = useContainer();
  const queryClient = useQueryClient();
  const { data = [], isLoading } = useQuery({
    queryKey: ['outdatedActions'],
    queryFn: () => container.call('actions:getOutdatedActions'),
  });

  return {
    result: data,
    isLoading,
    cancelActions: () =>
      queryClient.cancelQueries({ queryKey: ['outdatedActions'] }),
  };
}
