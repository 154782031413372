export default {
  'assistant:createActionChat': () =>
    import('./compose/createChat/createActionChat'),
  'assistant:createPipelineChat': () =>
    import('./compose/createChat/createPipelineChat'),
  'assistant:createListenerChat': () =>
    import('./compose/createChat/createListenerChat'),
  'assistant:getAllAssistants': () => import('./compose/getAllAssistants'),
  'assistant:getPipelineChat': () =>
    import('./compose/getChat/getPipelineChat'),
  'assistant:getActionChat': () => import('./compose/getChat/getActionChat'),
  'assistant:getListenerChat': () =>
    import('./compose/getChat/getListenerChat'),
  'assistant:getPipelineMessages': () =>
    import('./compose/getMessages/getPipelineMessages'),
  'assistant:getActionMessages': () =>
    import('./compose/getMessages/getActionMessages'),
  'assistant:getListenerMessages': () =>
    import('./compose/getMessages/getListenerMessages'),
  'assistant:sendActionMessage': () =>
    import('./compose/sendMessages/sendActionMessages'),
  'assistant:sendPipelineMessage': () =>
    import('./compose/sendMessages/sendPipelineMessages'),
  'assistant:sendListenerMessage': () =>
    import('./compose/sendMessages/sendListenerMessages'),
};
