import { Flex, Scroll, Skeleton } from '@nstrlabs/ixel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Separator from '../../../components/atoms/Separator';
import SettingsHeader from '../../../components/atoms/SettingsHeader';
import Filters from '../../../components/organisms/NewFilters';
import UseFiltersProvider from '../../../components/organisms/NewFilters/FiltersProvider';
import { useUsers } from '../../../hooks/controlAccess/useUsers';
import ActivityTable from './ActivityTable';
import { composeFilterToSelect, filters } from './filters';
import styles from './index.module.css';

const Activity = () => {
  const { t } = useTranslation();
  const { users, isLoading } = useUsers();
  const [pageSelected, setPageSelected] = useState(1);

  const createActivityComponent = () => {
    const { filtersToSelect } = composeFilterToSelect(users);

    const handleResetPagination = (page: number) => setPageSelected(page);
    return (
      <UseFiltersProvider
        value={{
          filtersToSelect,
          filters,
        }}
      >
        <Flex gap="xs">
          <Filters
            addMoreFilters
            onChangeValue={() => handleResetPagination(1)}
          />
        </Flex>
        <ActivityTable
          pageSelected={pageSelected}
          handlePagination={handleResetPagination}
        />
      </UseFiltersProvider>
    );
  };

  return (
    <Scroll
      className={styles.scrollContainer}
      paddingX="xl"
      style={
        {
          '--scroll-padding-right': 'var(--xs)',
          '--scrollbar-gutter': 'stable',
        } as React.CSSProperties
      }
    >
      <Flex flexDirection="column" className={styles.container}>
        <SettingsHeader
          squareClassName={styles.square}
          title={t('SETTINGS.TENANT.ACTIVITY.TITLE')}
          description={t('SETTINGS.TENANT.ACTIVITY.DESCRIPTION')}
          icon="list"
        />
        <Separator />
        {isLoading ? (
          <Flex gap="xs" data-testid="filter_skeletons_container">
            <Skeleton width={90} height={24} variant="rectangle" />
            <Skeleton width={190} height={24} variant="rectangle" />
            <Skeleton width={70} height={24} variant="rectangle" />
          </Flex>
        ) : users.length > 0 ? (
          createActivityComponent()
        ) : null}
      </Flex>
    </Scroll>
  );
};

export default Activity;
