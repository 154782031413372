import { Flex, Typography } from '@nstrlabs/ixel';
import clsx from 'clsx';
import styles from './index.module.css';

interface CountProps {
  count: number;
  limit?: number;
}

function Count({ count, limit }: CountProps) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className={clsx(styles.count)}
    >
      <Typography as="span" size="small" color="main-inverted" bold>
        {limit && count > limit ? `+${limit}` : count}
      </Typography>
    </Flex>
  );
}

export default Count;
