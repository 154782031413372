import { Flex } from '@nstrlabs/ixel';
import { capitalize, emptyFn, eventEmitter } from '@nstrlabs/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NoData from '../../../components/molecules/NoData';
import Pagination from '../../../components/organisms/FullTable/Pagination';
import SkeletonTable from '../../../components/organisms/FullTable/SkeletonTable';
import Table from '../../../components/organisms/FullTable/Table';
import { useNewFilters } from '../../../components/organisms/NewFilters/FiltersProvider';
import { useActivity } from '../../../hooks/activity/useActivity';
import { COLUMNS } from './ActivityColumns';
import styles from './index.module.css';

type ActivityTable = {
  pageSelected: number;
  handlePagination: (page: number) => void;
};

const ActivityTable = ({ pageSelected, handlePagination }: ActivityTable) => {
  const { t } = useTranslation();
  const emitter = useMemo(() => eventEmitter<{ type: 'deselect' }>(), []);
  const { filters } = useNewFilters();

  const transformationFilters = () => {
    return filters.reduce(
      (acc, current) => {
        if (current.type === 'category' && !current?.value?.length) {
          return acc;
        }
        if (
          current.type === 'date' &&
          current.field === 'rangeData' &&
          current.value
        ) {
          return {
            ...acc,
            from: Math.floor(new Date(current.value.from).getTime()),
            to: Math.floor(new Date(current.value.to).getTime()),
          };
        }
        if (current.type === 'category' && current.field === 'user') {
          return { ...acc, userId: current.value?.map(({ id }) => id) };
        }
        if (current.type === 'category' && current.field === 'operation') {
          return {
            ...acc,
            operation: current.value?.map(({ id }) => capitalize(id as string)),
          };
        }
        if (current.type === 'category' && current.field === 'entity') {
          return {
            ...acc,
            entity: current.value?.map(({ id }) => capitalize(id as string)),
          };
        }
        return acc;
      },
      {
        page: pageSelected,
        from: new Date().getTime(),
        to: new Date().getTime(),
      },
    );
  };

  const { activity, isLoading } = useActivity(transformationFilters());

  if (isLoading) return <SkeletonTable columns={6} />;

  if (activity && !isLoading) {
    const { data, page, limit, count } = activity;
    return (
      <Flex flexDirection="column" gap="xl">
        {data.length > 0 ? (
          <>
            <Table
              activeRow=""
              className={styles.table}
              columns={COLUMNS}
              data={data}
              disableClickSelection
              emitter={emitter}
              groupSelection=""
              hideHeader
              noData={
                <NoData
                  size="normal"
                  gap="xxl"
                  title={t('PIPELINE.MENU.NO_DATA.TITLE')}
                  subtitle={t('PIPELINE.MENU.NO_DATA.SUBTITLE')}
                  flexDirection="column"
                  iconName="search"
                />
              }
              onRowActive={emptyFn}
              title=""
              searchValue=""
            />
            <Pagination
              page={page}
              total={Math.ceil(count / limit)}
              onPageChange={handlePagination}
            />
          </>
        ) : (
          <NoData
            className={styles.emptySearch}
            size="normal"
            gap="xxl"
            title={t('PIPELINE.MENU.NO_DATA.TITLE')}
            subtitle={t('PIPELINE.MENU.NO_DATA.SUBTITLE')}
            flexDirection="column"
            iconName="search"
          />
        )}
      </Flex>
    );
  }

  return null;
};

export default ActivityTable;
