import {
  type Column,
  DataTable,
  type DataTableDensityType,
} from '@nstrlabs/ixel';

import type {
  GridReadyEvent,
  SelectionChangedEvent,
} from '@ag-grid-community/core';
import { type EventEmitter, invoke } from '@nstrlabs/utils';
import clsx from 'clsx';
import type { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableHeader from '../TableHeader';
import ColumnHeaderActionMenu from '../TableHeaderActionsMenu';
import styles from './index.module.css';

type Row<T = unknown> = {
  id: string;
} & {
  [key: string]: T;
};

const groupColumnsBy = <T,>(
  columns: Column<T>[],
  columnGroupIndex?: string,
): Column<T>[] =>
  columns.map((column) => ({
    ...column,
    rowGroup: column.id === columnGroupIndex,
    hidden: column.id === columnGroupIndex,
    sort: column.id === columnGroupIndex ? 'asc' : undefined,
  }));

export type TableProps<T> = {
  className?: string;
  columns: Column<T>[];
  data: T[];
  dataLength?: number;
  emitter?: EventEmitter<{ type: 'deselect' }>;
  hideHeader?: boolean;
  title: string;
  activeRow: string;
  searchValue?: string;
  groupSelection?: string;
  noData: React.ReactNode;
  disableClickSelection?: boolean;
  tableDensity?: DataTableDensityType;
  getRowId?: (row: T) => string;
  onSelectionChanged?: (event: SelectionChangedEvent) => void;
  onGridReady?: (event: GridReadyEvent) => void;
  onClickRow?: (row: Row, event?: Event | null) => void;
  onRowSelected?: (selected: T, total: T[]) => void;
  onRowActive: (newData: string) => void;
  onUpdateColumns?: (columns: Column<T>[]) => void;
};

export const buildColumns = <T,>(
  columns: Column<T>[],
  translation: TFunction<'translation', undefined>,
): Column<T>[] =>
  columns.map((column) => ({
    ...column,
    className: column.checkable
      ? clsx(column.className, styles.checkableColumn)
      : column.className,
    headerClassName: column.checkable
      ? clsx(column.headerClassName, styles.checkableColumn)
      : column.headerClassName,
    headerRenderer: column.checkable ? () => null : column.headerRenderer,
    cellRenderer: column.checkable ? () => null : column.cellRenderer,
    name: column.name ? translation(column.name) : column.id,
  }));

const Table = <T,>({
  className,
  columns: primitiveColumns,
  data,
  dataLength = data.length,
  emitter,
  hideHeader,
  title,
  activeRow,
  searchValue,
  groupSelection,
  noData,
  disableClickSelection,
  getRowId,
  tableDensity = 'comfortable',
  onSelectionChanged,
  onGridReady,
  onClickRow,
  onRowSelected,
  onRowActive,
  onUpdateColumns,
}: TableProps<T>) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState<Column<T>[]>(
    buildColumns(primitiveColumns, t),
  );

  useEffect(() => {
    setColumns((currentColumns) =>
      groupColumnsBy(currentColumns, groupSelection),
    );
  }, [groupSelection]);

  const handleClickRow = (row: Row, event?: Event | null) => {
    if (
      (event &&
        ((event.target as HTMLButtonElement).id === 'action-button' ||
          (event.target &&
            (event.target as HTMLButtonElement).closest('#action-button') !==
              null))) ||
      row === undefined
    ) {
      return;
    }

    invoke(onRowActive, row?.id);
    invoke(onClickRow, row, event);
  };

  return (
    <div className={clsx(styles.table, className)}>
      {hideHeader ? null : (
        <TableHeader
          columns={onUpdateColumns ? primitiveColumns : columns}
          dataLength={dataLength}
          onColumnUpdate={onUpdateColumns ? onUpdateColumns : setColumns}
          title={title}
        />
      )}
      <DataTable
        columnMenu={ColumnHeaderActionMenu}
        columns={onUpdateColumns ? primitiveColumns : columns}
        data={data}
        emitter={emitter}
        noData={noData}
        onClickRow={handleClickRow}
        onRowSelected={onRowSelected}
        onUpdateColumns={onUpdateColumns ? onUpdateColumns : setColumns}
        rowDensity={tableDensity}
        searchValue={searchValue}
        selectedRow={activeRow}
        disableClickSelection={disableClickSelection}
        onSelectionChanged={onSelectionChanged}
        onGridReady={onGridReady}
        getRowId={getRowId}
      />
    </div>
  );
};

export default Table;
