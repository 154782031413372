import {
  Button,
  Flex,
  Icon,
  NoData,
  OverlayLayout,
  Typography,
} from '@nstrlabs/ixel';
import { invoke, normalizeIncludes } from '@nstrlabs/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../../atoms/DropdownMenu';
import FilterCheckItem from '../FilterCheckItem';
import FilterItem from '../FilterItem';
import type { FilterCategoryType } from '../FiltersProvider';
import styles from './index.module.css';

export type DefaultField = {
  id: string;
  label: string | Record<string, string>;
};

export type FilterCategoryProps<
  T extends Record<K | string, string | Record<string, string>>,
  K extends string = 'id',
> = React.HTMLAttributes<HTMLDivElement> & {
  filter: FilterCategoryType<
    'category',
    Record<string, string | Record<string, string>>[],
    T
  >;
  buttonName: string;
  onDelete: () => void;
  keyLabel?: string;
  keyId?: K;
  keyToFilter?: string;
  handleSaveFilters: (
    filterToSave: FilterCategoryType<'category', Record<string, string>[], T>,
  ) => void;
  dataTestId?: string;
};

const FilterCategory = <
  T extends Record<K | string, string | Record<string, string>>,
  K extends string = 'id',
>({
  filter,
  buttonName,
  onDelete,
  keyToFilter = 'label',
  keyLabel = 'label',
  keyId = 'id' as K,
  handleSaveFilters,
  dataTestId = 'filter-categories-id',
}: FilterCategoryProps<T, K>) => {
  const {
    label,
    value,
    isOpen = true,
    isFixed,
    data,
    iconName,
    renderFilterItem,
    renderCheckboxLabel,
    customSearchFunction,
  } = filter;
  const { t } = useTranslation();
  const [opened, setOpened] = useState<boolean>(true);
  const [search, setSearch] = useState('');

  const onApply = (values: Record<K | string, string>[]) => {
    setOpened(!opened);
    handleSaveFilters({
      ...filter,
      value: values,
    });
  };

  const composeFieldSelected = (
    value: Record<K | string, string | Record<string, string>>[],
  ) => {
    if (value) {
      return value.reduce(
        (acc, current) => ({ ...{ [current[keyId] as string]: true }, ...acc }),
        {},
      );
    }
    return {};
  };
  const [fieldsSelected, setFieldsSelected] = useState<Record<string, boolean>>(
    () => composeFieldSelected(value),
  );
  const composeFilterFieldsSelected = (
    filterToSave: Record<string, boolean>,
  ) => {
    const genericDataField = Object.keys(filterToSave)
      .reduce(
        (acc, current) => [
          ...acc,
          filterToSave[current]
            ? data.find((field) => field[keyId] === current)
            : null,
        ],
        [],
      )
      .filter((genericFiler) => genericFiler);
    onApply(genericDataField as Record<K | string, string>[]);
  };
  const defaultSearchFunction = (data: T[], search: string) =>
    data?.filter((field) =>
      normalizeIncludes(field[keyToFilter] as string, search),
    );

  const dataFiltered = customSearchFunction
    ? customSearchFunction(data, search)
    : defaultSearchFunction(data, search);

  const checkFieldsSelectedEmpty = (
    fieldsToFilterSelected: Record<string, boolean>,
  ) =>
    !Object.keys(fieldsToFilterSelected).filter(
      (field) => fieldsSelected[field],
    ).length;

  const handleClose = () => {
    setSearch('');
    if (checkFieldsSelectedEmpty(fieldsSelected)) {
      invoke(onDelete);
    }
  };
  const composeFilterText = () => {
    if (value) {
      const labels = value.map((category) => t(category[keyLabel] as string));
      if (labels.length <= 3) {
        return labels.join(', ');
      }
      return `${labels[0]} ${t('FILTERS.CATEGORY.AND')} ${labels.length - 1} ${t(
        'FILTERS.CATEGORY.MORE',
      )}`;
    }
    return '';
  };

  const handleClickCheckbox = (
    checked: boolean,
    field: Record<string | K, string>,
  ) => {
    setFieldsSelected((prev) => ({
      ...prev,
      [field[keyId]]: checked,
    }));
  };
  return (
    <DropdownMenu
      defaultOpen={isOpen}
      onClose={handleClose}
      trigger={
        <FilterItem
          onDelete={onDelete}
          isFixed={isFixed}
          name={
            <Flex gap="xxs">
              {iconName ? <Icon name={iconName} /> : null}
              <Typography>{t(label)}</Typography>
              <Typography>{t('FILTERS.CATEGORY.IS')}</Typography>
              {renderFilterItem ? (
                renderFilterItem(value)
              ) : (
                <Typography bold>{composeFilterText()}</Typography>
              )}
            </Flex>
          }
        />
      }
    >
      {({ closeDropdown }) => (
        <div className={styles.dropdownContainer}>
          <OverlayLayout>
            {data?.length >= 10 ? (
              <OverlayLayout.Search
                placeholder="Search field"
                className={styles.search}
                onSearch={(e) => setSearch(e.target.value)}
              />
            ) : null}
            <OverlayLayout.Body>
              {dataFiltered.length ? (
                <Flex flexDirection="column" gap="md">
                  {dataFiltered.map((field) => (
                    <Flex alignItems="center" justifyContent="spaceBetween">
                      <Flex gap="xs">
                        <FilterCheckItem
                          dataTestId={dataTestId}
                          field={field}
                          keyId={keyId}
                          handleClickCheckbox={handleClickCheckbox}
                          fieldsSelected={fieldsSelected}
                          renderCheckboxText={
                            renderCheckboxLabel ? (
                              renderCheckboxLabel(
                                field[keyLabel] as unknown as T,
                              )
                            ) : (
                              <Typography
                                variant="body"
                                size="default"
                                as="h5"
                                bold
                              >
                                {t(field[keyLabel] as string)}
                              </Typography>
                            )
                          }
                        />
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              ) : (
                <Flex
                  flexDirection="column"
                  gap="md"
                  alignContent="center"
                  alignItems="center"
                  className={styles.noData}
                >
                  <NoData
                    title={t('FILTERS.CATEGORY.NO_CATEGORIES')}
                    iconName={iconName}
                  />
                </Flex>
              )}
            </OverlayLayout.Body>
            <OverlayLayout.Footer
              footerActions={
                <Button
                  variant="solid"
                  mode="primary"
                  onClick={() => {
                    composeFilterFieldsSelected(fieldsSelected);
                    closeDropdown();
                  }}
                >
                  {buttonName}
                </Button>
              }
            />
          </OverlayLayout>
        </div>
      )}
    </DropdownMenu>
  );
};

export default FilterCategory;
