import {
  Button,
  type Column,
  Flex,
  Icon,
  Tooltip,
  Typography,
} from '@nstrlabs/ixel';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownInfo from '../../../atoms/DropdownInfo';
import TableDensity from '../TableDensity';
import TableSettings from '../TableSettings';
import styles from './index.module.css';

const getColumnsDefaultPositionById = (columns: Column<unknown>[]): string[] =>
  columns.map((column) => column.id);

type TableHeaderProps = {
  columns: Column<unknown>[];
  dataLength: number;
  onColumnUpdate: (columns: Column<unknown>[]) => void;
  title: string;
};

const TableHeader = ({
  columns,
  dataLength,
  onColumnUpdate,
  title,
}: TableHeaderProps) => {
  const { t } = useTranslation();
  const columnsDefaultPosition = useMemo(
    () => getColumnsDefaultPositionById(columns),
    [columns],
  );

  return (
    <Flex justifyContent="spaceBetween" className={styles.tableHeader}>
      <Flex>
        <Flex alignItems="center" gap="xs">
          <DropdownInfo
            className={styles.dropdown}
            onTargetClick={(target: EventTarget) => target !== document.body}
            placement="bottom-start"
            trigger={
              <Tooltip
                className={styles.tooltip}
                content={
                  <Typography as="p" bold className={styles.tooltipText}>
                    {t('TABLE_SETTINGS.TOOLTIP')}
                  </Typography>
                }
                placement="top"
              >
                <Button
                  aria-label="Table settings"
                  mode="primary"
                  variant="link"
                  icon={<Icon name="settings" />}
                />
              </Tooltip>
            }
          >
            <TableSettings
              {...{ columns, columnsDefaultPosition }}
              onColumnUpdate={onColumnUpdate}
            />
          </DropdownInfo>
          <Typography bold>{title}</Typography>
          <Typography as="span" color="tertiary">
            ({dataLength})
          </Typography>
        </Flex>
      </Flex>
      <Flex alignItems="center" className={styles.sideGap}>
        <TableDensity key="density" />
      </Flex>
    </Flex>
  );
};

export default TableHeader;
