import { Suspense, lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { z } from 'zod';
import FeatureFlags from '../../../components/atoms/FeatureFlag';
import { CONTEXT_PREFIX } from '../../../context/actions/domain/BaseMetadata/ActionId';
import { VERSION_REGEX } from '../../../context/actions/domain/BaseMetadata/ActionVersion';
import WithPermissions from '../../../hooks/controlAccess/WithPermissions';
import AreValidParams from '../AreValidParams';
import { NestedDetailRoute } from '../NestedDetailsRoute';

const ActionDetail = lazy(() => import('../../../pages/ActionDetail'));
const ActionsSummary = lazy(() => import('../../../pages/ActionsSummary'));

export const actionDetailsParams = z.object({
  actionId: z.string().startsWith(CONTEXT_PREFIX),
  actionVersion: z.string().regex(VERSION_REGEX),
});

const validateActionsDetailParams = (params: Record<string, string>): boolean =>
  actionDetailsParams.safeParse(params).success;

export const actionsRoutes = (
  <Route
    path="actions"
    element={
      <WithPermissions resource="action">
        <NestedDetailRoute>
          <Suspense>
            <FeatureFlags
              flag="update-actions"
              fallback={<Navigate to="/" replace />}
            >
              <ActionsSummary />
            </FeatureFlags>
          </Suspense>
        </NestedDetailRoute>
      </WithPermissions>
    }
  >
    <Route
      path=":actionId/:actionVersion"
      element={
        <AreValidParams validate={validateActionsDetailParams}>
          <Suspense>
            <ActionDetail />
          </Suspense>
        </AreValidParams>
      }
    />
  </Route>
);
