import type { IconName } from '@nstrlabs/ixel';
import {
  type Reducer,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import type { FilterDateValuePrimitives } from '../../../context/shared/domain/Criteria/FilterDateValue/FilterDateValue';
import {
  addFilter,
  removeFilter,
  resetFilters,
  saveFilterConfiguration,
} from './utils';

export type DefaultCategory = {
  id: string;
  label: string | Record<string, string>;
};

export type RangeDate =
  | 'ONE_HOUR_AGO'
  | 'LAST_24_HOURS'
  | 'YESTERDAY'
  | 'LAST_7_DAYS'
  | 'LAST_WEEK'
  | 'LAST_28_DAYS'
  | 'LAST_MONTH';

export type BaseFilter = {
  field: string;
  label: string;
  iconName?: IconName;
  isSuggested?: boolean;
  allowDuplicates?: boolean;
  isOpen?: boolean;
  isFixed?: boolean;
  defaultPreset?: RangeDate;
};

export type FilterSelectorToSelect<T> = {
  type: 'selector';
  options: T[];
};

export type FilterCategoryToSelect<T> = {
  type: 'category';
  data: T[];
  customSearchFunction?: (data: T[], search: string) => T[];
  renderCheckboxLabel?: (
    label: Record<string, string>,
    search: string,
  ) => React.ReactElement;
  renderFilterItem?: (usersSelected: unknown) => React.ReactElement;
};

export type FilterToSelect =
  | (BaseFilter & { type: 'string' })
  | (BaseFilter & { type: 'number' })
  | (BaseFilter & { type: 'float' })
  | (BaseFilter & { type: 'integer' })
  | (BaseFilter & FilterCategoryToSelect<unknown>)
  | (BaseFilter & { type: 'date'; value: FilterDateValuePrimitives })
  | (BaseFilter &
      FilterSelectorToSelect<Record<string, string | IconName | undefined>>);
export type FilterWithValue<T, V> = BaseFilter & {
  id: string;
  type: T;
  value: V;
  index?: number;
};

export type FilterWithOperation<T, V> = BaseFilter &
  FilterWithValue<T, V> & {
    operation: string;
  };

export type FilterCategoryType<T, V, D> = BaseFilter &
  FilterWithValue<T, V> & {
    data: D[];
    renderFilterItem?: (usersSelected: unknown) => React.ReactElement;
    renderCheckboxLabel?: (label: D) => React.ReactElement;
    customSearchFunction?: (data: D[], search: string) => D[];
  };

export type FilterSelectorType<T, V> = BaseFilter &
  FilterWithValue<T, V> & {
    options: Record<string, string | IconName | undefined>[];
  };

export type Filter =
  | FilterWithOperation<'number', number>
  | FilterWithOperation<'float', number>
  | FilterWithOperation<'integer', number>
  | FilterWithOperation<'string', string>
  | FilterCategoryType<
      'category',
      Record<string, string | Record<string, string>>[],
      DefaultCategory
    >
  | FilterWithValue<'date', FilterDateValuePrimitives>
  | FilterSelectorType<'selector', string>;

export type FilterProviderState = {
  filtersToSelect: FilterToSelect[];
  filters: Filter[];
};

// type PayloadFilters = { filters: Filter[], filtersToSelect: FilterToSelect[] };

type Action = {
  type:
    | 'saveFilterConfiguration'
    | 'addFilter'
    | 'removeFilter'
    | 'dropdownState'
    | 'reset'
    | 'updateFiltersToSelect';
  payload: FilterProviderState;
};

export type FilterAndOperation = {
  operation?: string;
  value: Filter['value'];
} | null;

type Dispatchers = {
  addFilterDispatcher: (saveParams: Filter) => void;
  saveFilterConfigDispatcher: (saveParams: Filter) => void;
  removeFilterDispatcher: (saveParams: Filter) => void;
  resetFiltersDispatcher: (_element: Filter[]) => void;
  updateFiltersDispatcher: (
    _filter: Filter[],
    _filterToSelect: FilterToSelect[],
  ) => void;
};

const FiltersContextState = createContext({
  filtersToSelect: [] as FilterToSelect[],
  filters: [] as Filter[],
});
const FiltersCreateContextDispatcher = createContext({
  addFilterDispatcher: (_saveParams: Filter) => {},
  saveFilterConfigDispatcher: (_saveParams: Filter) => {},
  removeFilterDispatcher: (_saveParams: Filter) => {},
  resetFiltersDispatcher: (_element: Filter[]) => {},
  updateFiltersDispatcher: (
    _filter: Filter[],
    _filterToSelect: FilterToSelect[],
  ) => {},
});

function reducer(state: FilterProviderState, action: Action) {
  switch (action.type) {
    case 'addFilter':
      return { ...state, ...action.payload };
    case 'removeFilter':
      return { ...state, ...action.payload };
    case 'saveFilterConfiguration':
      return { ...state, ...action.payload };
    case 'reset':
      return { ...state, ...action.payload };
    case 'updateFiltersToSelect':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export const UseNewFiltersProvider = ({
  children,
  value = { filtersToSelect: [], filters: [] },
}: {
  children: React.ReactNode;
  value?: FilterProviderState;
}) => {
  const [{ filters, filtersToSelect }, dispatch] = useReducer<
    Reducer<FilterProviderState, Action>
  >(reducer, {
    filtersToSelect: value.filtersToSelect ?? [],
    filters: value.filters ?? [],
  });

  const addFilterDispatcher = useCallback(
    (filter: Filter) => {
      dispatch({
        type: 'addFilter',
        payload: addFilter({ filter, filtersToSelect, filters }),
      });
    },
    [filters, filtersToSelect],
  );

  const saveFilterConfigDispatcher = useCallback(
    (filter: Filter) => {
      dispatch({
        type: 'saveFilterConfiguration',
        payload: saveFilterConfiguration({ filter, filtersToSelect, filters }),
      });
    },
    [filters, filtersToSelect],
  );

  const removeFilterDispatcher = useCallback(
    (filter: Filter) => {
      dispatch({
        type: 'removeFilter',
        payload: removeFilter({ filter, filtersToSelect, filters }),
      });
    },
    [filters, filtersToSelect],
  );

  const resetFiltersDispatcher = useCallback(() => {
    dispatch({
      type: 'reset',
      payload: resetFilters(filters),
    });
  }, [filters]);

  const updateFiltersDispatcher = useCallback(
    (filters: Filter[], filtersToSelect: FilterToSelect[]) => {
      dispatch({
        type: 'updateFiltersToSelect',
        payload: {
          filters,
          filtersToSelect,
        },
      });
    },
    [],
  );

  const dispatchers = useMemo(
    () => ({
      saveFilterConfigDispatcher,
      removeFilterDispatcher,
      resetFiltersDispatcher,
      addFilterDispatcher,
      updateFiltersDispatcher,
    }),
    [
      saveFilterConfigDispatcher,
      removeFilterDispatcher,
      resetFiltersDispatcher,
      addFilterDispatcher,
      updateFiltersDispatcher,
    ],
  );

  return (
    <FiltersContextState.Provider
      value={{ filtersToSelect: filtersToSelect ?? [], filters: filters ?? [] }}
    >
      <FiltersCreateContextDispatcher.Provider value={dispatchers}>
        {children}
      </FiltersCreateContextDispatcher.Provider>
    </FiltersContextState.Provider>
  );
};

export const useNewFilters = (): FilterProviderState & Dispatchers => {
  const filtersState = useContext(FiltersContextState);
  const filtersDispatchers = useContext(FiltersCreateContextDispatcher);

  if (filtersState === null || filtersDispatchers === null) {
    throw new Error('useFilters should be used within ListenerCreate');
  }

  return { ...filtersState, ...filtersDispatchers };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const withFilterProvider = <P extends object>(
  Render: (props: P) => React.ReactNode,
) =>
  function (props: P) {
    return (
      <UseNewFiltersProvider value={{ filtersToSelect: [], filters: [] }}>
        <Render {...(props as JSX.IntrinsicAttributes & P)} />
      </UseNewFiltersProvider>
    );
  };

export default UseNewFiltersProvider;
