import { invoke } from '@nstrlabs/utils';
import clsx from 'clsx';
import { type Ref, forwardRef } from 'react';

import { Button, Flex, Icon, Typography } from '@nstrlabs/ixel';
import styles from './index.module.css';

export type HTMLFilterItemProps = React.ComponentPropsWithRef<'div'> & {
  name: React.ReactNode;
  onDelete: () => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onReset?: () => void;
  isFixed?: boolean;
  dataTestId?: string;
};

const FilterItem = (
  {
    className,
    name,
    onDelete,
    onClick,
    onReset,
    isFixed,
    dataTestId = 'dropdown-menu-trigger',
    ...props
  }: HTMLFilterItemProps,
  ref: Ref<HTMLElement>,
) => (
  <Flex
    {...props}
    data-cy={dataTestId}
    aria-label="FilterItem"
    alignItems="center"
    justifyContent="center"
    ref={ref}
    className={clsx(className, styles.container)}
    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      invoke(onClick, e);
    }}
  >
    <Typography>{name}</Typography>
    {isFixed ? null : (
      <Button
        data-cy={`${dataTestId}-button-delete`}
        className={styles.delete}
        aria-label="remove"
        variant="link"
        mode="primary"
        onClick={(e) => {
          e.stopPropagation();
          invoke(onDelete);
        }}
        icon={<Icon name="close" />}
      />
    )}
    {onReset ? (
      <Button
        data-cy={`${dataTestId}-button-reset`}
        className={styles.delete}
        aria-label="reset"
        variant="link"
        mode="primary"
        onClick={(e) => {
          e.stopPropagation();
          invoke(onReset);
        }}
        icon={<Icon name="remove" />}
      />
    ) : null}
  </Flex>
);

export default forwardRef(FilterItem);
